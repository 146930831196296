@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

// Variables
// $bg-color-dark: #111111;
// $bg-color-light: #333333;
// $text-color-light: #FFFFFF;
// $text-color-dark: #CCCCCC;
// $primary-color: #2196F3;
// $primary-color-hover: #1565C0;
// $border-color: #555555;

$bg-color-dark: #CCCCCC;
$bg-color-light: #FFFFFF;
$text-color-light: #111111;
$text-color-dark: #111111;
$primary-color: #FFFFFF;
$primary-color-hover: #FFFFFF;
$border-color: #FFFFFF;

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/*.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;
}
just commented this
 
.chat-container {
  flex: 1;
  overflow-y: visible;
  padding: 1rem;
}
*/

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #ffffff; // Or whatever background color you prefer
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.container, .container-fluid {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.col-md-8 {
  padding-left: 0;
  padding-right: 0;
}

.app-header {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}


.card-body {
  padding: 1rem;
}

.input-group {
  padding: 1rem;
  margin-bottom: 0;
}




body {
  font-family: 'Poppins', sans-serif;
}
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 2rem;

  .welcome-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: $text-color-dark;
  }

  .welcome-quote {
    font-size: 1.1rem;
    font-style: italic;
    color: $text-color-dark;
    margin-bottom: 2rem;
    max-width: 80%;
    line-height: 1.6;
  }

  .start-prompt {
    font-size: 1rem;
    color: $text-color-dark;
  }
}
.app-header {
  justify-content: space-between;
  padding: 1.25rem;
  background-color: $bg-color-light;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  box-sizing: border-box;

  .header-left {
    display: flex;
  }

  .header-right {
    display: flex;
    align-items: center;

    .menu-toggle {
      display: none;
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      color: $text-color-dark;
    }

    .header-links {
      display: flex;
      align-items: center;
      font-style: italic;

      a {
        color: $text-color-dark;
        text-decoration: none;
        margin-left: 1.5rem;
        font-size: 1.2rem;

        &:hover {
          text-decoration: underline;
        }
      }

      .icon {
        margin-right: 0.5rem;
        vertical-align: middle;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .header-right {
      .menu-toggle {
        display: block;
      }

      .header-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100%;
        right: 0;
        background-color: $bg-color-light;
        padding: 1rem;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        z-index: 1000;

        &.open {
          display: flex;
        }

        a {
          margin: 0.5rem 0;
        }
      }
    }
  }

// ... (keep the rest of your styles)

  // .logo-container {
  //   display: flex;
  //   align-items: center;
  // }

  // .app-logo {
  //   width: 60px;
  //   height: 60px;
  //   border-radius: 50%;
  //   cursor: pointer;
  //   transition: box-shadow 0.3s;
  //   position: relative;

  //   &:hover {
  //     box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);

  //     &::after {
  //       content: 'Click to send feedback';
  //       position: absolute;
  //       top: 100%;
  //       left: 50%;
  //       transform: translateX(-50%);
  //       padding: 8px 12px;
  //       background-color: $primary-color;
  //       color: $text-color-light;
  //       font-size: 14px;
  //       border-radius: 10px;
  //       white-space: nowrap;
  //       opacity: 0;
  //       transition: opacity 0.3s;
  //     }
  //   }
  // }

  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .app-logo {
    width: auto;
    height: auto;
    max-width: 60px;
    max-height: 60px;
    cursor: pointer;
    transition: box-shadow 0.3s;
    position: relative;
  
    &:hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  
      &::after {
        content: 'Click to send feedback';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 8px 12px;
        background-color: $primary-color;
        color: $text-color-light;
        font-size: 14px;
        border-radius: 10px;
        white-space: nowrap;
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
  }
  .company-name {
    margin-left: 1rem;
    font-size: 1.75rem;
    font-weight: 600;
    color: $text-color-light;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 1s ease-in-out;
  }


  .header-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  &.flipped .header-inner {
    transform: rotateY(180deg);
  }

  .header-front,
  .header-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
  }

  .header-front {
    background-color: None;
  }

  .header-back {
    color: $text-color-light;
    transform: rotateY(180deg);

    p {
      margin: 0;
      word-wrap: break-word;
      max-width: 100%;
    }

    a {
      color: $text-color-dark;
      text-decoration: none;
      margin-left: 1rem;

      &:hover {
        text-decoration: underline;
      }
    }

    .icon {
      margin-right: 0.5rem;
      vertical-align: middle;
    }

    .header-back-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      font-size: 1.2rem;
      height: 100%;

      @media only screen and (max-width: 600px) {
        font-size: 1rem;
      }
    }

    .header-links {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 0.5rem;

      a {
        margin: 0.25rem;
        font-size: 1.2rem;

        @media only screen and (max-width: 600px) {
          font-size: 1rem;
        }
      }
    }
  }
}

.chat-container {
  flex: 1;
  overflow-y: visible;
  padding: 2rem;
  background-color: $bg-color-light;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  //min-height: 550px;
  //max-height: 550px;
  position: relative;
}

input {
  font-family: 'Poppins', sans-serif;
}

.messages-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.message {
  display: inline-block;
  padding: 1rem 1.75rem;
  border-radius: 25px;
  max-width: 75%;
  word-wrap: break-word;
  font-size: 1rem;
  line-height: 1.6;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  &.user {
    align-self: flex-end;
    background-color: $primary-color;
    color: $text-color-light;
  }

  &.bot {
    align-self: flex-start;
    background-color: $bg-color-light;
    color: $text-color-dark;
  }

  .message-timestamp {
    font-size: 0.75rem;
    color: #999999;
    margin-top: 0.5rem;
  }
}

.typing-indicator {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
}

.input-group {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  flex-direction: column;
  border-top: 1px solid #e0e0e0;
  margin-top: 1rem;
  align-items: center;
  background-color: $bg-color-light;
  font:$text-color-dark;

  .form-control {
    flex: 1;
    padding: 1rem 1.25rem;
    border: none;
    border-radius: 30px;
    background-color: $bg-color-light;
    color: $text-color-dark;
    outline: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 0.5rem;
    font-size: 16px; // Larger font size for better readability on mobile
    height: auto; // Allow the input to grow with content
    min-height: 50px; // Set a minimum height for better touch targets
    border-radius: 20px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
  }

  .btn-custom {
    flex: 1;
    padding: 1rem 1.75rem;
    font-size: 1rem;
    border: none;
    border-radius: 30px;
    background-color: $primary-color;
    color: $text-color-light;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: $primary-color-hover;
      transform: translateY(-2px);
    }
  }
}


    

@media (min-width: 768px) {
  .input-group {
    flex-direction: row;
    align-items: center;

    .form-control {
      flex: 1;
      margin-bottom: 0;
      margin-right: 0.5rem;
      max-width: calc(100% - 200px);
    }

    .button-group {
      display: flex;
      justify-content: flex-end;
       width: auto;
    }

    .btn-custom {
      flex: none;
      width: auto;
    }
  }
}

html {
  background-color: $bg-color-dark;
}
.col-md-8{
  background-color: #ffffff;
}
.collapse-container button {
  background-color: $primary-color;
  color: $text-color-light;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;

  &:hover {
    background-color: $primary-color-hover;
    color: $text-color-light;
  }
}

.side-panel {
  background-color: $bg-color-light;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

  .accordion-item {
    border: none;
    margin-bottom: 1rem;
  }

  .accordion-header {
    margin-bottom: 0;
  }

  .accordion-button {
    background-color: $bg-color-dark;
    color: $text-color-dark;
    border: none;
    border-radius: 10px;
    padding: 1.25rem;
    font-size: 1rem;
    text-align: left;
    width: 100%;
    transition: background-color 0.3s;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    &:not(.collapsed) {
      background-color: $primary-color;
      color: $text-color-light;
      box-shadow: none;
    }
  }

  .accordion-body {
    padding: 1.25rem;
    background-color: $bg-color-light;
    color: $text-color-dark;
  }

  .list-group-item {
    background-color: $bg-color-light;
    color: $text-color-dark;
    border: none;
    padding: 1rem 1.25rem;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 10px;

    &:hover {
      background-color: $bg-color-dark;
    }
  }
}

/* .empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  color: $text-color-light;
  font-size: 1.25rem;
  min-height: 450px;

  i {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
}
*/

.form-label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: $text-color-dark;
}

.form-select,
.form-control {
  background-color: $bg-color-light;
  color: $text-color-dark;
  border: none;
  border-radius: 10px;
  padding: 1rem;
  font-size: 1rem;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.table-responsive {
  max-width: 100%;
  overflow-x: auto;

  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;

    th,
    td {
      padding: 1rem;
      text-align: left;
      vertical-align: top;
      border-top: 1px solid $border-color;
    }

    thead th {
      background-color: $primary-color;
      color: $text-color-light;
      font-weight: 600;
      text-align: left;
      padding: 1rem;
      vertical-align: middle;
      border-bottom: 2px solid $text-color-light;
    }

    tbody + tbody {
      border-top: 2px solid $border-color;
    }
  }
}

.modal-content {
  background-color: $bg-color-light;
  color: $text-color-dark;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

  .modal-header {
    border-bottom: 1px solid $border-color;
    padding: 1.25rem;
  }

  .modal-title {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .modal-body {
    padding: 1.25rem;
  }

  .modal-footer {
    border-top: 1px solid $border-color;
    padding: 1.25rem;
  }

  .btn-close {
    color: $text-color-dark;
    background-color: transparent;
    border: none;
    font-size: 1.75rem;
    opacity: 0.7;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }
}

pre {
  background-color: $bg-color-dark;
  color: $text-color-dark;
  padding: 1.25rem;
  border-radius: 10px;
  font-size: 1rem;
  overflow-x: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.add-train-buttons {
  display: flex;
  margin-bottom: 1rem;

  p {
    margin-right: 15px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }

  .add-button {
    margin-right: 15px !important;
  }
}

p svg {
  vertical-align: middle;
}

.debug-only {
  display: none;
}

.overall-loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-color: $primary-color;
  z-index: 100;
}

.btn-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 30px;
  background-color: $primary-color;
  color: $text-color-light;
  cursor: not-allowed;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  .spinner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $text-color-light;
    border-top-color: $primary-color-hover;
    animation: spin 1s ease-in-out infinite;
    margin-right: 0.5rem;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-message {
    font-size: 14px;
    color: $text-color-light;
    margin: 0;
  }
}

.sample-questions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
  background-color: $bg-color-light;

  .btn {
    margin: 0.25rem;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    background-color: $bg-color-light;
    color: $text-color-light;
    border: 2px solid $primary-color;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: $primary-color;
      color: $text-color-light;
    }
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 768px) {
  body, input, button, .form-control, .btn-custom, .accordion-button, .list-group-item, .modal-content {
    font-size: 14px;
  }

  .chat-container, .side-panel, .input-group, .form-control, .btn-custom, .modal-content {
    padding: 1rem;
    border-radius: 10px;
    .empty-state{
      max-width: 95%;
    }
  }

  .message {
    padding: 0.5rem 1rem;
  }

  .message-timestamp, .loading-message {
    font-size: 12px;
  }

  .app-header {
    flex-direction: column;
    padding: 0.75rem;

    .company-name {
      margin-top: 0.5rem;
    }
  }
}

.app-footer {
  background-color: $bg-color-light;
  padding: 1.5rem;
  text-align: center;
  font-size: 0.9rem;
  color: $text-color-dark;
  margin-top: 2rem;

  p {
    margin-bottom: 0.5rem;
  }

  a {
    color: $primary-color;
    text-decoration: none;
    margin: 0 0.5rem;

    &:hover {
      text-decoration: underline;
    }
  }

  .icon {
    margin-right: 0.5rem;
    vertical-align: middle;
  }
}
